
import { scrollToTop } from "../utils/scrollToTop";
import { useEffect, useRef } from "react";
import Footer from "../components/Footer";
import Headerb from "../components/Headerb";
import { MdVpnKey } from "react-icons/md";
import { FaDroplet } from "react-icons/fa6";
import { FaTools } from "react-icons/fa";
import { Link } from "react-router-dom";


function Aboutus() {
  const topele = useRef(null);

  useEffect(() => {
    if (topele.current) {
      scrollToTop(topele)
    }
  })



  return (
    <>
      <Headerb />
      <h2 className="font-bold text-center text-3xl font-rubik mx-3 mt-20">About Us </h2>
      <div>
        <div className=" pb-5 mb-5 ">
          <h2 className="mt-8 mb-5  font-bold text-center text-2xl font-rubik mx-8">Who we are</h2>
          <p className=" text-gray-900 mt-3 mx-4  text-base text-justify " >Real estate has historically been the asset class that has created the highest wealth for investors around the world. However, large capital investment, specialised investment knowledge, asset management capabilities and understanding of market cycles has restricted the asset class to institutional investors, ultra high net worth individuals, pension and sovereign funds.</p>
          <p className=" text-gray-900 mt-3 mx-4  text-base text-justify" >Property Share was founded by a team with deep experience in institutional real estate investing and technology with the aim to democratise real estate investing for ordinary investors by providing institutional quality analysis, asset management and liquidity at much lower investment thresholds.</p>


          <div className="py-5 mt-5">
            <h2 className="mt-8  font-bold text-center text-3xl font-rubik mx-8">$1 billion+</h2>
            <p className=" text-gray-500 mt-3 text-center text-lg" >Investment Experience</p>
            <h2 className="mt-10  font-bold text-center text-3xl font-rubik mx-8">₹1,320 crs+</h2>
            <p className=" text-gray-500 mt-3 text-center text-lg" >Properties Funded</p>
            <h2 className="mt-10  font-bold text-center text-3xl font-rubik mx-8">170,000+</h2>
            <p className=" text-gray-500 mt-3 text-center text-lg" >Users</p>
          </div>
        </div>

        <div className="pb-5 mb-5 ">
          <h2 className="mt-8 mb-5  font-bold text-center text-2xl font-rubik mx-8">What we do</h2>
          <div className="">
            <div class="relative bg-white py-6 px-6 border mt-10 rounded-3xl  my-4 shadow-xl">
              <div class=" text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl bg-btncolor left-4 -top-6">
                <MdVpnKey className="text-xl" />
              </div>
              <div class="mt-8">
                <p class="text-xl font-rubik font-semibold my-2">Access</p>
                <p class="text-base font-rubik">to vetted Grade A commercial real estate with 8-10% yields and 17-20% projected returns</p>
              </div>
            </div>
            <div class="relative bg-white py-6 px-6 border mt-10 rounded-3xl  my-4 shadow-xl ">
              <div class=" text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl bg-btncolor left-4 -top-6">
                <FaDroplet className="text-xl" />
              </div>
              <div class="mt-8">
                <p class="text-xl font-rubik font-semibold my-2">Liquidity</p>
                <p class="text-base font-rubik">through access to a separate proprietary resale platform</p>
              </div>
            </div>
            <div class="relative bg-white py-6 px-6 border rounded-3xl  my-4 shadow-xl mt-10">
              <div class=" text-white flex items-center absolute rounded-full py-4 px-4 shadow-xl bg-btncolor left-4 -top-6">
                <FaTools className="text-xl" />
              </div>
              <div class="mt-8">
                <p class="text-xl font-rubik font-semibold my-2">Asset Management</p>
                <p class="text-base font-rubik">active asset management including leasing and property management to increase value</p>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <button className="bg-btncolor px-8 py-3 rounded-3xl text-lg font-rubik text-white">Get Start</button>
          </div>
        </div>

        <h4 className="text-2xl mt-16 ms-5 font-semibold mb-2 text-center  font-rubik">Follow Us</h4>
        <div className="flex ms-5 mt-8 py-2 justify-center mb-20">
          <Link to="#">
            <img src="assets\images\facebook.png" alt="" width="50px" className="me-2  border rounded-full" />
          </Link>
          <Link to="#" className="text-white ">
            <img src="assets\images\instagram.png" alt="" width="55px" className="me-2   border rounded-full" />
          </Link>
          <Link to="#" className="text-white ">
            <img src="assets\images\linkedin.png" alt="" width="50px" className="me-2   border rounded-full" />
          </Link>

        </div>

      </div>

      <Footer />

    </>
  );
}

export default Aboutus;