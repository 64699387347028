import React, { useEffect, useState } from 'react';
import { IoMdMail } from "react-icons/io";
import { IoBookmarks } from "react-icons/io5";
import { ImHome } from "react-icons/im";
import { IoIosContact } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";

function Navbar() {
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case '/home':
        setActiveIndex(0);
        break;
      case '/savedproperty':
        setActiveIndex(2);
        break;
      case '/contact':
        setActiveIndex(3);
        break;
      case '/profile':
        setActiveIndex(4);
        break;
      default:
        setActiveIndex(0);
    }
  }, [location.pathname]);

  return (
    <>
      <div className='flex justify-center mx-2'>
        <div className="flex justify-between fixed bottom-0 bg-white p-3  shadow-btncolor border border-black/30 rounded-t-2xl w-full ">
          <Link to="/home">
            <div
              className={`flex flex-col items-center px-8 cursor-pointer ${activeIndex === 0 ? 'bg-btncolor text-white rounded-full p-2' : ''}`}
            >
              <ImHome className={`text-2xl ${activeIndex === 0 ? 'text-white' : 'text-btncolor'}`} />
            </div>
          </Link>

          <Link to="/savedproperty">
            <div
              className={`flex flex-col items-center px-8 cursor-pointer ${activeIndex === 2 ? 'bg-btncolor text-white rounded-full p-2' : ''}`}
            >
              <IoBookmarks className={`text-2xl ${activeIndex === 2 ? 'text-white' : 'text-btncolor'}`} />
            </div>
          </Link>

          <Link to="/contact">
            <div
              className={`flex flex-col items-center px-8 cursor-pointer ${activeIndex === 3 ? 'bg-btncolor text-white rounded-full p-2' : ''}`}
            >
              < IoMdMail className={`text-3xl ${activeIndex === 3 ? 'text-white' : 'text-btncolor'}`} />
            </div>
          </Link>

          <Link to="/profilemenu">
            <div
              className={`flex flex-col items-center px-8 cursor-pointer ${activeIndex === 4 ? 'bg-btncolor text-white rounded-full p-2' : ''}`}
            >
              <IoIosContact className={`text-3xl ${activeIndex === 4 ? 'text-white' : 'text-btncolor'}`} />
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Navbar;
