import { useEffect, useRef } from "react";
import Headerb from "../components/Headerb";
import { scrollToTop } from "../utils/scrollToTop";

function Feedback() {
  const topele = useRef(null);
  useEffect(() => {
    if (topele.current) {
      scrollToTop(topele);
    }
  }, []);

  return (
    <div className="min-h-screen flex flex-col justify-between">
      <div>
        <p ref={topele}>.</p>
        <Headerb />

        <h1 className="mt-16 font-bold text-3xl font-rubik ms-2">Send Feedback</h1>
        <p className="font-rubik text-gray-500 ms-2 me-5 mt-2 text-sm font-semibold">
          Tell us what you love about the app, or what we could be doing better.
        </p>

        <form className="max-w-md mx-3 mt-10">
          <div className="grid md:grid-cols-2 md:gap-6">
            <div className="relative z-0 w-full mb-5 group">
              <textarea
                name="floating_message"
                id="floating_message"
                className="block py-4 px-0 w-full text-sm text-btncolor bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-btncolor focus:outline-none focus:ring-0 focus:border-btncolor peer"
                placeholder=" "
                required
              />
              <label
                htmlFor="floating_message"
                className="peer-focus:font-medium absolute text-sm text-btncolor dark:text-btncolor duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-btncolor peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Enter Feedback
              </label>
            </div>
          </div>
        </form>
      </div>

      <div className="mt-auto px-3 pb-5">
        <button
          type="submit"
          className="text-white bg-btncolor hover:bg-btncolor focus:ring-4 focus:outline-none focus:ring-btncolor font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-btncolor"
        >
          Submit Feedback
        </button>
      </div>
    </div>
  );
}

export default Feedback;
