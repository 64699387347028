import React, { useState } from 'react';
import { IoLocation } from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from 'react-router-dom';
import { FaLocationCrosshairs } from "react-icons/fa6";
import { VscLocation } from "react-icons/vsc";

const SearchBox = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const locations = [
    { name: 'Anudeep Medical And General Store', address: 'Sector 3, Malviya Nagar, Jaipur', distance: '70 m' },
    { name: 'Uncle Mess Restaurant', address: 'Sector 3, Malviya Nagar, 3/234, Near Community Hall, Jaipur, Rajasthan', distance: '77 m' },
    { name: 'Lucky Apartment', address: 'Sector 3, Malviya Nagar, Jaipur', distance: '149 m' },
    { name: 'Icarus Builders', address: 'Shivanand Marg, Vidyut Abhiyanta Colony, Sector 4, Malviya Nagar, Jaipur', distance: '253 m' },
    { name: 'Sparsh Unisex Salon And Boutique', address: 'Shivanand Marg, Sector 4, Malviya Nagar, Jaipur, Rajasthan', distance: '259 m' },
    { name: 'Raghunandan Apartment', address: 'Sector 3, Malviya Nagar, Jaipur', distance: '359 m' },
    { name: 'Laxmi Girls PG', address: 'Durga Vihar, Sector 3, Malviya Nagar, Jaipur', distance: '700 m' },
  ];

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <>
      {/* First div */}
      <div className="bg-white flex items-center justify-between rounded-full p-2 mt-16 mx-1" onClick={togglePopup}>
        <div className='flex items-center'>
          <div>
            <IoLocation className='text-btncolor text-4xl' />
          </div>
          <div className='ms-1'>
            <div className='flex items-center'>
              <p className='text-lg text-btncolor font-extrabold font-rubik'>Sector 3 </p>
              <span><IoIosArrowDown /></span>
            </div>
            <p className='text-sm font-semibold text-gray-400'>Malviya Nagar, Jaipur</p>
          </div>
        </div>
      </div>

      {/* Second div (popup) */}
      {isPopupOpen && (
        <div className="fixed inset-0 bg-gray-100 p-4 min-h-screen z-50">
          {/* Header */}
          <div className="flex items-center mb-4">
            <button className="text-xl" onClick={togglePopup}><IoIosArrowDown /></button>
            <h1 className="text-lg font-semibold ml-4">Select a location</h1>
          </div>

          {/* Search Input */}
          <div className="mb-4">
            <input
              type="text"
              placeholder="Search for area, street name..."
              className="w-full p-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-btncolor"
            />
          </div>

          {/* Use Current Location */}
          <div className="bg-white p-4 rounded-lg shadow-sm mb-4 flex items-center">
            <div className="flex items-center text-btncolor">
              <FaLocationCrosshairs className='text-2xl' />
            </div>
            <div className=' '>
              <p className="ml-2 font-semibold">Use current location</p>
              <p className="ml-2 text-sm text-gray-600">Someshwarpuri, Malviya Nagar, Jaipur</p>
            </div>
          </div>

          {/* Add Address */}
          <button className="w-full p-3 mb-4 rounded-lg bg-white border border-btncolor text-btncolor font-semibold shadow-sm">
            + Add Address
          </button>

          {/* Nearby Locations */}
          <h2 className="text-gray-500 text-sm font-semibold mb-2">Nearby Locations</h2>
          <div className="space-y-3 overflow-auto h-full">
            {locations.map((location, index) => (
              <div key={index} className="bg-white p-4 rounded-lg shadow-sm flex items-center">
                <div className="flex items-center text-btncolor">
                  <VscLocation className="text-xl w-8 h-8" />
                </div>
                <div className="">
                  <span className="ml-2 font-semibold">{location.name}</span>
                  <span className="ml-2 block text-sm text-gray-600">{location.address}</span>
                  <span className="ml-2 text-xs text-gray-500">{location.distance}</span>
                </div>
              </div>
            ))}
          </div>

        </div>
      )}
    </>
  );
};

export default SearchBox;
