import React, { useState } from 'react';
import axios from 'axios';
import Headerb from '../components/Headerb';

const Changepassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validationMessage, setValidationMessage] = useState('');
  const [apiResponseMessage, setApiResponseMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordChange = (e) => {
    const { id, value } = e.target;
    if (id === 'oldPassword') {
      setOldPassword(value);
    } else if (id === 'newPassword') {
      setNewPassword(value);
    } else if (id === 'confirmPassword') {
      setConfirmPassword(value);
    }

    if (id === 'confirmPassword' && newPassword !== value) {
      setValidationMessage('Passwords do not match!');
    } else {
      setValidationMessage('');
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async () => {
    if (newPassword !== confirmPassword) {
      setValidationMessage('Passwords do not match!');
      return;
    }

    const token = sessionStorage.getItem('token');
    const payload = {
      old_password: Number(oldPassword),
      new_password: Number(newPassword),
      new_password_confirmation: Number(confirmPassword),
    };

    try {
      const response = await axios.put(
        'https://kamaupoot.microcominternationals.com/public/api/update-password',
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setApiResponseMessage(response.data.message);
    } catch (error) {
      setApiResponseMessage(
        error.response?.data?.message || 'An error occurred while updating the password.'
      );
    }
  };

  return (
    <>
      <Headerb />
      <section className="grid h-screen place-content-center  text-slate-500">
        <div className="mb-10 text-center text-btncolor">
          <h1 className="text-3xl font-bold  font-rubik tracking-widest">Change Password</h1>
          {apiResponseMessage && (
            <p className="text-center text-gray-600 font-rubik text-sm mt-4">{apiResponseMessage}</p>
          )}
        </div>
        <div className="flex flex-col items-center justify-center space-y-6">
          <input
            type={showPassword ? 'text' : 'password'}
            id="oldPassword"
            name="oldPassword"
            placeholder="Old Password"
            value={oldPassword}
            onChange={handlePasswordChange}
            className="w-80  rounded-full border border-black/50  p-2 px-4 focus:bg-slate-800  "
          />
          <input
            type={showPassword ? 'text' : 'password'}
            id="newPassword"
            name="newPassword"
            placeholder="New Password"
            value={newPassword}
            onChange={handlePasswordChange}
            className="w-80  rounded-full border border-black/50  p-2 px-4 focus:bg-slate-800"
          />
          <div>
            <input
              type={showPassword ? 'text' : 'password'}
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={handlePasswordChange}
              className="w-80  rounded-full border border-black/50  p-2 px-4 focus:bg-slate-800"
            />
            {validationMessage && (
              <p className="text-center text-gray-600 font-rubik text-sm">{validationMessage}</p>
            )}
          </div>
          <button
            onClick={toggleShowPassword}
            className="rounded-full bg-btncolor p-2 px-4 text-white hover:bg-gray-600"
          >
            {showPassword ? 'Hide' : 'Show'} Password
          </button>
          <button
            onClick={handleSubmit}
            className="rounded-full bg-gray-600 p-2 px-4 text-white"
          >
            Change Password
          </button>
        </div>
      </section>
    </>
  );
};

export default Changepassword;
