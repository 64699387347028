import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (




    <footer className="w-full bg-gray-400 py-10">

      <div className="mx-auto flex max-w-6xl flex-col items-start space-x-8 md:flex-row ">
        <div className="w-full px-4 md:w-1/2 lg:px-0">
          <img src="https://kamaupoot.microcominternationals.com/public/kamaupoot.svg" alt="" className="my-3" style={{ width: "70%" }} />
          {/* <h1 className="ms-5 my-3 text-white font-rubik font-bold text-2xl">HOMECO</h1> */}
          <h4 className="text-lg mt-5 ms-5 font-semibold mb-2 text-white font-serif">Follow us on:</h4>
          <div className="flex ms-5">
            <Link to="#">
              <img src="assets\images\facebook.png" alt="" width="25px" className="me-2 bg-white border rounded-full" />
            </Link>
            <Link to="#" className="text-white ">
              <img src="assets\images\instagram.png" alt="" width="25px" className="me-2  bg-white border rounded-full" />
            </Link>
            <Link to="#" className="text-white ">
              <img src="assets\images\linkedin.png" alt="" width="25px" className="me-2  bg-white border rounded-full" />
            </Link>
          </div>
        </div>
        <div className="mt-8 grid grid-cols-2 gap-6 md:mt-0 lg:w-3/4 lg:grid-cols-3">
          <div className="mb-8 md:mb-0 me-3">
            <h4 className="text-xl font-semibold mb-2 text-white font-serif">Know about us</h4>
            <ul>

              <li>
                <Link to="/profile" className="text-white ">
                  Profile
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-white ">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <div className="mb-8 md:mb-0">
            <h4 className="text-xl font-semibold mb-2 text-white font-serif">You need to know</h4>
            <ul>

              <li>
                <Link to="/t&c" className="text-white ">
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link to="/policy" className="text-white ">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>

        </div>

      </div>
      <div className="mt-8 text-center text-white text-sm mx-3">
        © Copyright 2024 propertyshare - All Rights Reserved
      </div>
    </footer>

  );
};

export default Footer;
