import { LuBedDouble } from "react-icons/lu";
import { Link } from "react-router-dom";

function Buyahome() {
  return (
    <>

      <div className="relative mx-1 border border-gray-400 rounded-lg mt-5 ">
        <Link to="/propertydetail" className="relative inline-block w-full transform transition-transform duration-300 ease-in-out">
          <div className="rounded-lg">
            <div className="relative flex h-60 justify-center overflow-hidden rounded-lg">
              <div className="w-full transform transition-transform duration-500 ease-in-out hover:scale-110">
                <img src="assets\images\banner.jpg" alt="" />
              </div>

              <div className="absolute bottom-0 mb-3 flex justify-center">
                <div className="flex space-x-5 overflow-hidden rounded-lg bg-white/70 px-4 py-1 shadow">
                  <p className="flex items-center font-medium text-gray-500"><LuBedDouble className="text-xl me-1" />4 bd</p>
                  <p className="flex items-center font-medium text-gray-500">2ba</p>
                  <p className="flex items-center font-medium text-gray-500"> 1493&sup2; m</p>
                </div>
              </div>
              {/* <span className="absolute left-0 top-0 z-10 ml-3 mt-3 inline-flex select-none rounded-lg bg-black opacity-50 px-3 py-2 text-sm font-medium text-white"> 360&deg; </span> */}
            </div>

            <div className="mx-2 mb-2">
              <div className="mt-4 grid grid-cols-2">
                <div className="flex items-center">
                  <div className="relative">
                    <h2 className="line-clamp-1 text-base font-medium text-gray-800 md:text-lg" title="New York">Statue of Liberty</h2>
                    <p className="mt-2 line-clamp-1 text-sm text-gray-800" title="New York, NY 10004, United States">New York, NY 10004, United States</p>
                  </div>
                </div>
                <div className="flex items-center justify-end">
                  <p className="text-primary inline-block whitespace-nowrap rounded-xl font-semibold leading-tight">
                    <span className="text-sm uppercase"> $ </span>
                    <span className="text-lg">3,200</span>/m
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>

    </>
  );

}

export default Buyahome;
