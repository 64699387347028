import React, { useState, useRef, useEffect } from "react";
import Headerb from "../components/Headerb";
import { scrollToTop } from "../utils/scrollToTop";
import { IoIosArrowDown } from "react-icons/io";

function Support() {
  const [activeIndex, setActiveIndex] = useState(null);
  const topele = useRef(null);

  useEffect(() => {
    if (topele.current) {
      scrollToTop(topele);
    }
  }, []);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <Headerb />
      <h2
        ref={topele}
        className="font-bold text-center text-3xl font-rubik mx-3 mt-20"
      >
        Support
      </h2>

      <div className="relative mt-5">
        <h6 className="mb-0">
          <button
            onClick={() => toggleAccordion(1)}
            className="relative flex items-center w-full p-4 font-semibold text-left transition-all ease-in border-b border-solid cursor-pointer border-slate-100 text-slate-700 rounded-t-1 group text-dark-500"
          >
            <span>What is Material Tailwind?</span>

            <IoIosArrowDown className={`absolute right-0 pt-1  text-xl me-3 transition-transform fa fa-chevron-down ${activeIndex === 1 ? "rotate-180" : ""
              }`} />
          </button>
        </h6>
        <div
          className={`overflow-hidden transition-all duration-300 ease-in-out ${activeIndex === 1 ? "h-auto" : "h-0"
            }`}
        >
          <div className="p-4 text-sm leading-normal text-blue-gray-500/80">
            We're not always in the position that we want to be at. We're
            constantly growing. We're constantly making mistakes. We're
            constantly trying to express ourselves and actualize our dreams.
          </div>
        </div>
      </div>

      <div className="relative mb-3">
        <h6 className="mb-0">
          <button
            onClick={() => toggleAccordion(2)}
            className="relative flex items-center w-full p-4 font-semibold text-left transition-all ease-in border-b border-solid cursor-pointer border-slate-100 text-slate-700 rounded-t-1 group text-dark-500"
          >
            <span>How to use Material Tailwind?</span>
            <IoIosArrowDown className={`absolute right-0 pt-1 text-xl me-3 transition-transform fa fa-chevron-down ${activeIndex === 2 ? "rotate-180" : ""
              }`} />
          </button>
        </h6>
        <div
          className={`overflow-hidden transition-all duration-300 ease-in-out ${activeIndex === 2 ? "h-auto" : "h-0"
            }`}
        >
          <div className="p-4 text-sm leading-normal text-blue-gray-500/80">
            We're not always in the position that we want to be at. We're
            constantly growing. We're constantly making mistakes. We're
            constantly trying to express ourselves and actualize our dreams.
          </div>
        </div>
      </div>

      <div className="relative mb-3">
        <h6 className="mb-0">
          <button
            onClick={() => toggleAccordion(3)}
            className="relative flex items-center w-full p-4 font-semibold text-left transition-all ease-in border-b border-solid cursor-pointer border-slate-100 text-slate-700 rounded-t-1 group text-dark-500"
          >
            <span>What can I do with Material Tailwind?</span>
            <IoIosArrowDown className={`absolute right-0 pt-1 text-xl me-3 transition-transform fa fa-chevron-down ${activeIndex === 3 ? "rotate-180" : ""
              }`} />
          </button>
        </h6>
        <div
          className={`overflow-hidden transition-all duration-300 ease-in-out ${activeIndex === 3 ? "h-auto" : "h-0"
            }`}
        >
          <div className="p-4 text-sm leading-normal text-blue-gray-500/80">
            We're not always in the position that we want to be at. We're
            constantly growing. We're constantly making mistakes. We're
            constantly trying to express ourselves and actualize our dreams.
          </div>
        </div>
      </div>
    </>
  );
}

export default Support;
