import { useEffect, useRef, useState } from "react";
import Headerb from "../components/Headerb";
import { FaRegBookmark, FaBookmark } from 'react-icons/fa';
import { MdOutlineCurrencyRupee } from "react-icons/md";
import PieChart from "../components/Piechart";
import { Link } from "react-router-dom";
import { scrollToTop } from "../utils/scrollToTop";
import { IoShareSocialSharp } from "react-icons/io5";
import axios from 'axios';

function Propertydetail() {
  const [bookmarked, setBookmarked] = useState(false);
  const handleBookmarkClick = () => {
    setBookmarked(!bookmarked);
  };

  const topele = useRef(null);
  useEffect(() => {
    if (topele.current) {
      scrollToTop(topele);
    }
  }, []);

  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPropertyDetails = async () => {
      const propertyId = sessionStorage.getItem('property_id');
      const token = sessionStorage.getItem('token');

      if (!propertyId) {
        setError('Property ID not found in session storage.');
        setLoading(false);
        return;
      }

      if (!token) {
        setError('Authentication token not found.');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.post(
          'https://kamaupoot.microcominternationals.com/public/api/property-details',
          { id: propertyId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const propertyData = response.data.properties[0];
        // console.log(response.data.properties[0])
        if (propertyData) {
          setProperty(propertyData);
        } else {
          setError('Property not found.');
        }
      } catch (err) {
        setError('Failed to fetch property details.');
      } finally {
        setLoading(false);
      }
    };

    fetchPropertyDetails();
  }, []);

  if (loading) return <div><p className="text-center mt-10">Loading...</p></div>;
  if (error) return <div>{error}</div>;

  return (
    <>
      <p ref={topele}>.</p>
      <Headerb />
      <div className="max-w-sm mx-auto bg-white rounded-t-xl overflow-hidden md:max-w-2xl mt-10 mb-12">
        <div className="relative">
          <img className="w-full object-cover" src={`https://kamaupoot.microcominternationals.com/public/${property.images[0].images}`} alt="Property" style={{ height: "500px" }} />
          <div className="absolute inset-0 bg-black opacity-10"></div>
          <div className="absolute top-0 left-0 m-4 bg-black font-rubik opacity-50 text-white text-sm p-2 rounded">Open for sale</div>
          <div className="absolute top-0 right-0 m-4">
            <button onClick={handleBookmarkClick}>
              {bookmarked ? (
                <FaBookmark className="h-6 w-6 text-white" />
              ) : (
                <FaRegBookmark className="h-6 w-6 text-white" />
              )}
            </button>
          </div>
          <div className="absolute bottom-0 left-0 mb-10 ms-4">
            <h1 className="text-3xl font-rubik font-semibold text-white">{property.title}</h1>
            <p className="text-lg text-white">{property.location}</p>
          </div>
        </div>
        <div className="p-4">
          <IoShareSocialSharp className="text-2xl float-end flex mb-4" />

          <p className="font-rubik font-bold text-2xl">Location Highlights</p>
          <p className="mt-2 text-gray-600 text-xs text-justify font-gilroy line-clamp-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis dicta quo quod, blanditiis voluptatum laborum nisi beatae, debitis tempore reiciendis maiores vitae commodi adipisci aliquid dolor minus magni eveniet. In aliquid natus consequuntur voluptate atque facere modi. Necessitatibus nostrum quas, cum earum sequi eum est dolor quibusdam temporibus. Exercitationem debitis et nobis quasi fuga.</p>

          <p className="font-rubik font-bold text-2xl mt-4">Investment Overview</p>
          <p className="mt-2 text-gray-600 text-xs text-justify font-gilroy line-clamp-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis dicta quo quod, blanditiis voluptatum laborum nisi beatae, debitis tempore reiciendis maiores vitae commodi adipisci aliquid dolor minus magni eveniet. In aliquid natus consequuntur voluptate atque facere modi. Necessitatibus nostrum quas, cum earum sequi eum est dolor quibusdam temporibus. Exercitationem debitis et nobis quasi fuga.</p>

          <div className="flex items-center mt-4 font-rubik">
            <span className="text-2xl font-bold">Investment Returns</span>
          </div>

          <p className="font-rubik font-bold mt-2 text-xl">Investment Amount</p>
          <div className="flex items-center">
            <MdOutlineCurrencyRupee />
            <p className="font-rubik font-bold">{property.high_returns}L</p>
          </div>

          <div className="flex items-center mt-2 text-gray-600 font-rubik">
            <span className="mr-2">Area</span>
            <span className="mr-2">{property.area} Sq.Ft.</span>
          </div>

          <h2 className="font-rubik text-gray-600 mt-5 mb-2">Monthly Cash Flow 	&#11163;</h2>
          <div className="relative">
            <div className="mb-4 flex h-12 overflow-hidden rounded bg-gray-100 text-xs">
              <div className="flex flex-col justify-center bg-red-400 text-white" style={{ width: "33.33%" }}>
                <span className="text-center text-white font-rubik">Monthly Rent</span>
                <span className="text-center text-white font-rubik">₹ 1,48,09,133</span>
              </div>
              <div className="flex flex-col justify-center bg-blue-400 text-white" style={{ width: "33.33%" }}>
                <span className="text-center text-white font-rubik">Property Management Fee</span>
                <span className="text-center text-white font-rubik">₹ 18,29,167</span>
              </div>
              <div className="flex flex-col justify-center bg-purple-400 text-white" style={{ width: "33.33%" }}>
                <span className="text-center text-white font-rubik">10% TDS</span>
                <span className="text-center text-white font-rubik">₹ 16,45,459</span>
              </div>
            </div>
          </div>

          <h2 className="text-xl font-rubik font-semibold text-black mt-5 mb-2">Property Pricing</h2>
          <PieChart />
        </div>
      </div>
      <div className="flex w-full bottom-0 fixed bg-white p-2 gap-2">
        <Link to="/form" className="bg-btncolor/90 text-white py-2 px-4 w-full rounded-full font-rubik text-center">Book a Call</Link>
      </div>
    </>
  );
}

export default Propertydetail;
