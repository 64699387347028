import { IoLocationOutline } from "react-icons/io5";
import { MdCurrencyRupee } from "react-icons/md";
import { Link } from "react-router-dom";
import Headerb from "../components/Headerb";
import { scrollToTop } from "../utils/scrollToTop";
import { useEffect, useRef } from "react";
import Footer from "../components/Footer";

function Allproject() {
  const topele = useRef(null);
  useEffect(() => {
    if (topele.current) {
      scrollToTop(topele)
    }
  })
  return (
    <>
      <p ref={topele}>.</p>
      <Headerb />
      <h1 className="text-2xl font-bold font-rubik mt-16 ms-3">All Projects</h1>
      <div className="mb-10">
        <div className="mx-1 mt-3">
          <div className="w-full rounded border">

            <img src={`assets/images/img3.jpg`} alt="Laptop" className="h-[200px] w-full rounded-md object-cover" />
            <div className="p-4">
              <h1 className="text-xl font-semibold font-rubik line-clamp-1">Shivay Splendora</h1>
              <div className="mt-1 flex items-center text-sm text-gray-600">
                <IoLocationOutline className="mr-1 text-lg" />
                <p className="text-sm">Waghodia Road,Vadodara</p>
              </div>
              <div className="flex items-center text-sm text-gray-600">
                <MdCurrencyRupee className="mr-1 text-custom-pink text-lg" />
                <p className="text-custom-pink text-lg">38.99 - 45.99 L</p>
              </div>
              <p className="text-sm text-gray-600">3, 4 BHK Apartment</p>
            </div>

            <Link to="/propertydetail">
              <div className="flex items-center justify-center bg-black/90 p-4 border-t space-x-2">
                <button className="ml-1 text-white font-rubik font-bold">View Property</button>
              </div>
            </Link>

          </div>
        </div>
        <div className="mx-1 mt-3">
          <div className="w-full rounded border">

            <img src={`assets/images/img3.jpg`} alt="Laptop" className="h-[200px] w-full rounded-md object-cover" />
            <div className="p-4">
              <h1 className="text-xl font-semibold font-rubik line-clamp-1">Shivay Splendora</h1>
              <div className="mt-1 flex items-center text-sm text-gray-600">
                <IoLocationOutline className="mr-1 text-lg" />
                <p className="text-sm">Waghodia Road,Vadodara</p>
              </div>
              <div className="flex items-center text-sm text-gray-600">
                <MdCurrencyRupee className="mr-1 text-custom-pink text-lg" />
                <p className="text-custom-pink text-lg">38.99 - 45.99 L</p>
              </div>
              <p className="text-sm text-gray-600">3, 4 BHK Apartment</p>
            </div>

            <Link to="/propertydetail">
              <div className="flex items-center justify-center bg-black/90 p-4 border-t space-x-2">
                <button className="ml-1 text-white font-rubik font-bold">View Property</button>
              </div>
            </Link>

          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Allproject;