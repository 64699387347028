import { useEffect, useRef } from "react";
import Footer from "../components/Footer";
import Headerb from "../components/Headerb";
import { scrollToTop } from "../utils/scrollToTop";

function Privacypolicy() {
  const topele = useRef(null);
  useEffect(() => {
    if (topele.current) {
      scrollToTop(topele)
    }
  })

  return (
    <>
      <p ref={topele}>.</p>
      <Headerb />
      <h1 className="mt-20 font-bold text-3xl font-rubik mx-3">Privacy Policy</h1>
      <div>
        <p className="mt-8 mx-3 text-justify">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, commodi?<span className="underline decoration-2 font-bold text-custom-pink"> www.dsndfn.com </span> Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga, debitis. Totam quod voluptates quae nam eveniet minus fugit ratione dignissimos nihil eius, error maiores tempora corrupti, nobis dolor quibusdam repellendus, labore vel laborum iste reiciendis quasi harum ex. Nobis est, amet tenetur ab quos odio deleniti, magnam eligendi officiis veniam perspiciatis doloremque non vero odit sunt libero. Asperiores aliquam cumque saepe repellendus, atque accusantium corporis hic rerum, sequi, tempora iusto.
        </p>

        <h3 className="mt-5 mb-3 font-bold text-lg mx-3">1. Lorem ipsum dolor sit amet consectetur.</h3>
        <p className="mb-5 mx-3 text-justify">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Impedit aspernatur ullam quisquam unde magnam facere non veritatis optio nisi quibusdam?</p>

        <p className="mb-5 mx-3 text-justify"> <span className="font-semibold">Account information:
        </span>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa, distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? Vel adipisci, fuga sapiente consectetur qui dolorem unde dicta facilis, recusandae minus consequuntur. </p>
        <p className="mb-5 mx-3 text-justify"> <span className="font-semibold">Telephone numbers:
        </span> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa, distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? Vel adipisci, fuga sapiente consectetur qui dolorem unde dicta facilis, recusandae minus consequuntur.</p>
        <p className="mb-5 mx-3 text-justify"> <span className="font-semibold">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa,</span> distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? Vel adipisci, fuga sapiente consectetur qui dolorem unde dicta facilis, recusandae minus consequuntur.</p>
        <p className="mb-5 mx-3 text-justify"> <span className="font-semibold">Feedback:
        </span>  If you contact us to provide feedback, register a complaint, or ask a question, we will record any personal information and other content you provide to respond effectively.</p>
        <p className="mb-5 mx-3 text-justify"> <span className="font-semibold">Activity:
        </span>  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa, distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? Vel adipisci, fuga sapiente consectetur qui dolorem unde dicta facilis, recusandae minus consequuntur.</p>
        <p className="mb-5 mx-3 text-justify"> <span className="font-semibold">Cookies:
        </span> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa, distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? Vel adipisci, fuga sapiente consectetur qui dolorem unde dicta facilis, recusandae minus consequuntur.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa, distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? </p>
        <p className="mb-5 mx-3 text-justify"> <span className="font-semibold">Enforcement:
        </span>  We may use the information collected, including personal information, to investigate, enforce, and apply our Terms of Service and Privacy Policy.</p>






        <h3 className="mt-5 mb-3 font-bold text-lg mx-3">2. Transfer of information</h3>
        <p className="mb-5 mx-3 text-justify">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa, distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? Vel adipisci, fuga sapiente consectetur qui dolorem unde dicta facilis, recusandae minus consequuntur.</p>
        <p className="mb-5 mx-3 text-justify">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa, distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? Vel adipisci, fuga sapiente consectetur qui dolorem unde dicta facilis, recusandae minus consequuntur.</p>
        <p className="mb-5 mx-3 text-justify">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa, distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? Vel adipisci, fuga sapiente consectetur qui dolorem unde dicta facilis, recusandae minus consequuntur.</p>
        <p className="mb-5 mx-3 text-justify">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa, distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? Vel adipisci, fuga sapiente consectetur qui dolorem unde dicta facilis, recusandae minus consequuntur.</p>
        <p className="mb-5 mx-3 text-justify">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa, distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? Vel adipisci, fuga sapiente consectetur qui dolorem unde dicta facilis, recusandae minus consequuntur.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa, distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? Vel adipisci, fuga sapiente consectetur qui dolorem unde dicta facilis, recusandae minus consequuntur.</p>
        <p className="mb-5 mx-3 text-justify">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa, distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? Vel adipisci, fuga sapiente consectetur qui dolorem unde dicta facilis, recusandae minus consequuntur.</p>

        <h3 className="mt-5 mb-3 font-bold text-lg mx-3">3.Other Information</h3>
        <p className="mb-5 mx-3 text-justify">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa, distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? Vel adipisci, fuga sapiente consectetur qui dolorem unde dicta facilis, recusandae minus consequuntur.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa, distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? Vel adipisci, fuga sapiente consectetur qui dolorem unde dicta facilis, recusandae minus consequuntur.</p>

        <h3 className="mt-5 mb-3 font-bold text-lg mx-3">4. Links</h3>
        <p className="mb-5 mx-3 text-justify">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa, distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? Vel adipisci, fuga sapiente consectetur qui dolorem unde dicta facilis, recusandae minus consequuntur.</p>

        <h3 className="mt-5 mb-3 font-bold text-lg mx-3">5. Controlling your personal data</h3>
        <p className="mb-5 mx-3 text-justify">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa, distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? Vel adipisci, fuga sapiente consectetur qui dolorem unde dicta facilis, recusandae minus consequuntur.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa, distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? Vel adipisci, fuga sapiente consectetur qui dolorem unde dicta facilis, recusandae minus consequuntur.</p>
        <p className="mb-5 mx-3 text-justify">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa, distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? Vel adipisci, fuga sapiente consectetur qui dolorem unde dicta facilis, recusandae minus consequuntur.</p>

        <h3 className="mt-5 mb-3 font-bold text-lg mx-3">6. Security</h3>
        <p className="mb-5 mx-3 text-justify">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa, distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? Vel adipisci, fuga sapiente consectetur qui dolorem unde dicta facilis, recusandae minus consequuntur.</p>
        <p className="mb-5 mx-3 text-justify">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa, distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? Vel adipisci, fuga sapiente consectetur qui dolorem unde dicta facilis, recusandae minus consequuntur.</p>
        <p className="mb-5 mx-3 text-justify">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa, distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? Vel adipisci, fuga sapiente consectetur qui dolorem unde dicta facilis, recusandae minus consequuntur.</p>

        <h3 className="mt-5 mb-3 font-bold text-lg mx-3">7. E-mails and opt-out</h3>
        <p className="mb-5 mx-3 text-justify">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa, distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? Vel adipisci, fuga sapiente consectetur qui dolorem unde dicta facilis, recusandae minus consequuntur.</p>
        <p className="mb-5 mx-3 text-justify">However, please note that despite your email preferences, we may still send you notices regarding updates to our Terms of Use or Privacy Policy.</p>

        <h3 className="mt-5 mb-3 font-bold text-lg mx-3">8. Terms and modifications to this Privacy Policy</h3>
        <p className="mb-5 mx-3 text-justify">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa, distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? Vel adipisci, fuga sapiente consectetur qui dolorem unde dicta facilis, recusandae minus consequuntur.</p>
        <p className="mb-5 mx-3 text-justify">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa, distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? Vel adipisci, fuga sapiente consectetur qui dolorem unde dicta facilis, recusandae minus consequuntur.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa, distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? Vel adipisci, fuga sapiente consectetur qui dolorem unde dicta facilis, recusandae minus consequuntur.</p>
        <p className="mb-5 mx-3 text-justify">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa, distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? Vel adipisci, fuga sapiente consectetur qui dolorem unde dicta facilis, recusandae minus consequuntur.</p>
        <p className="mb-5 mx-3 text-justify">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa, distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? Vel adipisci, fuga sapiente consectetur qui dolorem unde dicta facilis, recusandae minus consequuntur.</p>


        <h3 className="mt-5 mb-3 font-bold text-lg mx-3">9. Dispute resolution</h3>
        <p className="mb-5 mx-3 text-justify">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa, distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? Vel adipisci, fuga sapiente consectetur qui dolorem unde dicta facilis, recusandae minus consequuntur.</p>
        <h3 className="mt-5 mb-3 font-bold text-lg mx-3">10. Contacting the website and access to your personal information</h3>
        <p className="mb-5 mx-3 text-justify">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa, distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? Vel adipisci, fuga sapiente consectetur qui dolorem unde dicta facilis, recusandae minus consequuntur.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa, distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? Vel adipisci, fuga sapiente consectetur qui dolorem unde dicta facilis, recusandae minus consequuntur. any inquiries regarding this Privacy Policy, you can contact us using the provided contact information.</p>
        <h3 className="mt-5 mb-3 font-bold text-lg mx-3">11. Deleting your data</h3>
        <p className="mb-5 mx-3 text-justify">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maxime ipsa, distinctio consectetur amet impedit excepturi aliquam provident velit inventore illum consequatur itaque? Vel adipisci, fuga sapiente consectetur qui dolorem unde dicta facilis, recusandae minus consequuntur. <span className="underline decoration-2 font-bold text-custom-pink"> link. </span></p>
      </div>

      <Footer />
    </>
  );
}

export default Privacypolicy;