
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
import Home from "../pages/Home";
import Privacypolicy from "../pages/Privacypolicy";
import Termsandcondtion from "../pages/Termsandcondition";
import Login from "../pages/Login";
import Start from "../pages/Start";
import Savedproperty from "../pages/Savedproperty";
import Profile from "../pages/Profile";
import Contactus from "../pages/Contactus";
import Propertydetail from "../pages/Propertydetail";
import Form from "../pages/Form";
import Allproject from "../pages/Allproject";
import Profilemenu from "../pages/Profilemenu";
import Aboutus from "../pages/Aboutus";
import Feedback from "../pages/Feedback";
import Support from "../pages/Support";
import Register from "../pages/Register";
import Changepassword from "../pages/Changepassword";
import Categoriesproperty from "../pages/Categoriesproperty";




export default function Routess() {
  return (
    <Router>

      <Routes>
        <Route path='/' element={<Start />} />
        <Route path='/home' element={<Home />} />
        <Route path='/allproject' element={<Allproject />} />
        <Route path='/category' element={<Categoriesproperty />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/propertydetail' element={<Propertydetail />} />
        <Route path='/form' element={<Form />} />
        <Route path='/contact' element={<Contactus />} />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/changepassword' element={<Changepassword />} />
        <Route path='/savedproperty' element={<Savedproperty />} />
        <Route path='/policy' element={<Privacypolicy />} />
        <Route path='/t&c' element={<Termsandcondtion />} />
        <Route path='/profilemenu' element={<Profilemenu />} />
        <Route path='/aboutus' element={<Aboutus />} />
        <Route path='/feedback' element={<Feedback />} />
        <Route path='/support' element={<Support />} />
      </Routes>

    </Router>
  )
}
