import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaUserCircle } from 'react-icons/fa';
import { MdOutlinePhoneIphone } from 'react-icons/md';
import { CiMail } from 'react-icons/ci';
import Headerb from '../components/Headerb';
import Navbar from '../components/Navbar';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Profile() {
  const [userDetails, setUserDetails] = useState({
    name: '',
    phone_number: '',
    email: '',
  });

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const phoneNo = sessionStorage.getItem('phone_no');

        if (phoneNo) {
          setUserDetails(prevDetails => ({
            ...prevDetails,
            phone_number: phoneNo,
          }));
        }

        const response = await axios.get('https://kamaupoot.microcominternationals.com/public/api/profile', {
          headers: {
            'Authorization': token,
          },
        });

        const userData = response.data.user;
        setUserDetails(prevDetails => ({
          ...prevDetails,
          name: userData.name || '',
          phone_number: phoneNo || userData.phone_no || '',
          email: userData.email || ''
        }));
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    fetchUserProfile();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails(prevDetails => ({
      ...prevDetails,
      [name]: value
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = sessionStorage.getItem('token');
      const response = await axios.post('https://kamaupoot.microcominternationals.com/public/api/profile', {
        name: userDetails.name,
        email: userDetails.email,
      }, {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        toast.success('Profile updated successfully!');

      }
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Failed to update profile.');
    }
  };

  return (
    <>
      <Headerb />

      <h2 className="mt-20 font-bold text-2xl font-rubik text-center mx-3">My Profile</h2>

      <form className="max-w-sm mx-3 mt-5" onSubmit={handleFormSubmit}>
        <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Username</label>
        <div className="flex mb-4">
          <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-btncolor border border-e-0 border-black rounded-s-md  dark:border-black">
            <FaUserCircle className="text-lg text-white" />
          </span>
          <input
            type="text"
            id="name"
            name="name"
            className="rounded-none rounded-e-lg  border border-black  block flex-1 min-w-0 w-full text-sm p-2.5  dark:text-white"
            placeholder="Enter your name"
            value={userDetails.name}
            onChange={handleInputChange}
            required
          />
        </div>

        <label htmlFor="phone_number" className="block text-sm font-medium text-gray-900 dark:text-white">Mobile No.</label>
        <div className="flex mt-2 mb-4">
          <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-btncolor border border-e-0 border-black rounded-s-md">
            <MdOutlinePhoneIphone className="text-lg text-white" />
          </span>
          <input
            type="tel"
            id="phone_number"
            name="phone_number"
            className="rounded-none rounded-e-lg  border border-black  block flex-1 min-w-0 w-full text-sm p-2.5  dark:text-white"
            placeholder="Enter your mobile number"
            value={userDetails.phone_number}
            onChange={handleInputChange}
            maxLength="10"
            required
          />
        </div>

        <label htmlFor="email" className="block text-sm font-medium text-gray-900 dark:text-white">Email</label>
        <div className="flex mt-2 mb-4">
          <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-btncolor border border-e-0 border-black rounded-s-md">
            <CiMail className="text-lg text-white" />
          </span>
          <input
            type="email"
            id="email"
            name="email"
            className="rounded-none rounded-e-lg  border border-black  block flex-1 min-w-0 w-full text-sm p-2.5  dark:text-white"
            placeholder="Enter your email"
            value={userDetails.email}
            onChange={handleInputChange}
            required
          />
        </div>

        <button className="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-btncolor rounded-lg focus:ring-opacity-50">
          Update
        </button>
      </form>
      <ToastContainer autoClose={1000} />
      <Navbar />
    </>
  );
}

export default Profile;
