

function Articals() {
  return (
    <>
      <h1 className="text-xl font-rubik font-bold ms-3 mb-3">Top articles on leasing</h1>

      <div className="flex flex-col m-auto p-auto ">
        <div className="flex overflow-x-scroll pb-10 hide-scroll-bar">
          <div className="flex flex-nowrap lg:ml-10">

            <div className="inline-block px-3">
              <div className="flex mb-2 w-full" >
                <img src="assets\images\banner.jpg" alt="" className="rounded" width="100px" />
                <div style={{ marginLeft: "0.75rem ", marginRight: "5.75rem" }} >
                  <h3 className="ms-2 font-rubik text-sm font-semibold">Impact of TOD policy on Nodia,Ghaziabad</h3>
                  <p className="ms-2 text-xs text-gray-500">Feb 09,2024</p>
                </div>
                <hr />
              </div>
            </div>
            <div className="inline-block px-3">
              <div className="flex mb-2 w-full" >
                <img src="assets\images\banner.jpg" alt="" className="rounded" width="100px" />
                <div style={{ marginLeft: "0.75rem ", marginRight: "5.75rem" }} >
                  <h3 className="ms-2 font-rubik text-sm font-semibold">Impact of TOD policy on Nodia,Ghaziabad</h3>
                  <p className="ms-2 text-xs text-gray-500">Feb 09,2024</p>
                </div>
                <hr />
              </div>
            </div>
            <div className="inline-block px-3">
              <div className="flex mb-2 w-full" >
                <img src="assets\images\banner.jpg" alt="" className="rounded" width="100px" />
                <div style={{ marginLeft: "0.75rem ", marginRight: "5.75rem" }} >
                  <h3 className="ms-2 font-rubik text-sm font-semibold">Impact of TOD policy on Nodia,Ghaziabad</h3>
                  <p className="ms-2 text-xs text-gray-500">Feb 09,2024</p>
                </div>
                <hr />
              </div>
            </div>
            <div className="inline-block px-3">
              <div className="flex mb-2 w-full" >
                <img src="assets\images\banner.jpg" alt="" className="rounded" width="100px" />
                <div style={{ marginLeft: "0.75rem ", marginRight: "5.75rem" }} >
                  <h3 className="ms-2 font-rubik text-sm font-semibold">Impact of TOD policy on Nodia,Ghaziabad</h3>
                  <p className="ms-2 text-xs text-gray-500">Feb 09,2024</p>
                </div>
                <hr />
              </div>
            </div>
            <div className="inline-block px-3">
              <div className="flex mb-2 w-full" >
                <img src="assets\images\banner.jpg" alt="" className="rounded" width="100px" />
                <div style={{ marginLeft: "0.75rem ", marginRight: "5.75rem" }} >
                  <h3 className="ms-2 font-rubik text-sm font-semibold">Impact of TOD policy on Nodia,Ghaziabad</h3>
                  <p className="ms-2 text-xs text-gray-500">Feb 09,2024</p>
                </div>
                <hr />
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default Articals;