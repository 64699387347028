import axios from 'axios';
import { IoLocationOutline } from "react-icons/io5";
// import { MdCurrencyRupee } from "react-icons/md";
import { Link } from "react-router-dom";
import Headerb from "../components/Headerb";
import { scrollToTop } from "../utils/scrollToTop";
import { useEffect, useRef, useState } from "react";
import Footer from "../components/Footer";

function Categoriesproperty() {
  const [properties, setProperties] = useState([]);
  const topele = useRef(null);

  useEffect(() => {
    if (topele.current) {
      scrollToTop(topele);
    }

    const fetchProperties = async () => {
      const category_id = sessionStorage.getItem('category_id');
      const token = sessionStorage.getItem('token');

      if (category_id && token) {
        try {
          const response = await axios.get(`https://kamaupoot.microcominternationals.com/public/api/property-category/${category_id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          // console.log("API Response:", response.data);

          if (response.data && Array.isArray(response.data.properties)) {
            setProperties(response.data.properties);
          } else if (response.data && response.data.message) {
            console.error("API Message:", response.data.message);
          } else {
            console.error("No properties found in the API response.");
          }
        } catch (error) {
          console.error("Error fetching properties:", error);
        }
      } else {
        console.error("Category ID or token not found in session storage.");
      }
    };

    fetchProperties();
  }, []);

  const handleViewProperty = (propertyId) => {
    sessionStorage.setItem('property_id', propertyId);
  };

  return (
    <>
      <p ref={topele}>.</p>
      <Headerb />
      <h1 className="text-2xl font-bold font-rubik mt-16 ms-3">Properties</h1>
      <div className="mb-10">
        {properties.length > 0 ? properties.map(property => (
          <div className="mx-1 mt-3" key={property.id}>
            <div className="w-full rounded border">
              <img src={`https://kamaupoot.microcominternationals.com/public/${property.images[0].images}`} alt="Property" className="h-[200px] w-full rounded-md object-cover" />
              <div className="p-4">
                <h1 className="text-xl font-semibold font-rubik line-clamp-1">{property.title}</h1>
                <div className="mt-1 flex items-center text-sm text-gray-600 font-bold">
                  <IoLocationOutline className="mr-1 mt-1 text-lg font-bold" />
                  <p className="text-base">{property.location}</p>
                </div>
                <div className="flex items-center text-sm text-gray-600">
                  {/* <MdCurrencyRupee className="mr-1 text-custom-pink text-lg" /> */}
                  <p className="text-custom-pink font-gilroy font-bold text-base">Area : {property.area}</p>
                </div>
                {/* <p className="text-sm text-gray-600">{property.type}</p> */}
              </div>
              <Link to={`/propertydetail`} onClick={() => handleViewProperty(property.id)}>
                <div className="flex items-center justify-center bg-btncolor p-4 border-t space-x-2">
                  <button className="ml-1 text-white font-rubik font-bold">View Property</button>
                </div>
              </Link>
            </div>
          </div>
        )) : (
          <p className="text-center text-gray-600">No properties available.</p>
        )}
      </div>
      <Footer />
    </>
  );
}

export default Categoriesproperty;
