import { FaStar } from "react-icons/fa";

function Testimonials() {
  return (
    <>
      <h1 className="text-xl font-rubik font-bold ms-3 mb-3">What our users have to say...</h1>
      <div className="flex flex-col m-auto p-auto mb-10">
        <div className="flex overflow-x-scroll pb-10 hide-scroll-bar">
          <div className="flex flex-nowrap lg:ml-10">
            <div className="inline-block px-3">
              <div className="w-64  max-w-xs overflow-hidden rounded-lg shadow-md  hover:shadow-xl transition-shadow duration-300 ease-in-out">
                <div className="p-4 border rounded-lg max-w-md mx-auto ">
                  <div className="flex justify-between items-center ">
                    <div className="flex items-center">
                      <img src="assets/images/default.png" alt="" className="w-10 h-10 rounded-full me-2" />
                      <span className="font-semibold font-rubik">John Doe</span>
                    </div>
                    <div className="flex items-center bg-green-500 border rounded-xl px-3 ">
                      <p className="text-lg text-white me-1">5</p>
                      <FaStar className="mr-1 text-white" />
                    </div>
                  </div>
                  <div className="mt-4">
                    <p className="text-gray-700 font-gilroy">This is the review content. The user writes their thoughts and opinions here. It can be a few lines long and will be styled accordingly</p>
                  </div>
                </div>


              </div>
            </div>
            <div className="inline-block px-3">
              <div className="w-64  max-w-xs overflow-hidden rounded-lg shadow-md  hover:shadow-xl transition-shadow duration-300 ease-in-out">
                <div className="p-4 border rounded-lg max-w-md mx-auto ">
                  <div className="flex justify-between items-center ">
                    <div className="flex items-center">
                      <img src="assets/images/default.png" alt="" className="w-10 h-10 rounded-full me-2" />
                      <span className="font-semibold font-rubik">John Doe</span>
                    </div>
                    <div className="flex items-center bg-green-500 border rounded-xl px-3 ">
                      <p className="text-lg text-white me-1">5</p>
                      <FaStar className="mr-1 text-white" />
                    </div>
                  </div>
                  <div className="mt-4">
                    <p className="text-gray-700 font-gilroy">This is the review content. The user writes their thoughts and opinions here. It can be a few lines long and will be styled accordingly</p>
                  </div>
                </div>


              </div>
            </div>
            <div className="inline-block px-3">
              <div className="w-64  max-w-xs overflow-hidden rounded-lg shadow-md  hover:shadow-xl transition-shadow duration-300 ease-in-out">
                <div className="p-4 border rounded-lg max-w-md mx-auto ">
                  <div className="flex justify-between items-center ">
                    <div className="flex items-center">
                      <img src="assets/images/default.png" alt="" className="w-10 h-10 rounded-full me-2" />
                      <span className="font-semibold font-rubik">John Doe</span>
                    </div>
                    <div className="flex items-center bg-green-500 border rounded-xl px-3 ">
                      <p className="text-lg text-white me-1">5</p>
                      <FaStar className="mr-1 text-white" />
                    </div>
                  </div>
                  <div className="mt-4">
                    <p className="text-gray-700 font-gilroy">This is the review content. The user writes their thoughts and opinions here. It can be a few lines long and will be styled accordingly</p>
                  </div>
                </div>


              </div>
            </div>
            <div className="inline-block px-3">
              <div className="w-64  max-w-xs overflow-hidden rounded-lg shadow-md  hover:shadow-xl transition-shadow duration-300 ease-in-out">
                <div className="p-4 border rounded-lg max-w-md mx-auto ">
                  <div className="flex justify-between items-center ">
                    <div className="flex items-center">
                      <img src="assets/images/default.png" alt="" className="w-10 h-10 rounded-full me-2" />
                      <span className="font-semibold font-rubik">John Doe</span>
                    </div>
                    <div className="flex items-center bg-green-500 border rounded-xl px-3 ">
                      <p className="text-lg text-white me-1">5</p>
                      <FaStar className="mr-1 text-white" />
                    </div>
                  </div>
                  <div className="mt-4">
                    <p className="text-gray-700 font-gilroy">This is the review content. The user writes their thoughts and opinions here. It can be a few lines long and will be styled accordingly</p>
                  </div>
                </div>


              </div>
            </div>
            <div className="inline-block px-3">
              <div className="w-64  max-w-xs overflow-hidden rounded-lg shadow-md  hover:shadow-xl transition-shadow duration-300 ease-in-out">
                <div className="p-4 border rounded-lg max-w-md mx-auto ">
                  <div className="flex justify-between items-center ">
                    <div className="flex items-center">
                      <img src="assets/images/default.png" alt="" className="w-10 h-10 rounded-full me-2" />
                      <span className="font-semibold font-rubik">John Doe</span>
                    </div>
                    <div className="flex items-center bg-green-500 border rounded-xl px-3 ">
                      <p className="text-lg text-white me-1">5</p>
                      <FaStar className="mr-1 text-white" />
                    </div>
                  </div>
                  <div className="mt-4">
                    <p className="text-gray-700 font-gilroy">This is the review content. The user writes their thoughts and opinions here. It can be a few lines long and will be styled accordingly</p>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Testimonials;