
import Buyahome from "../components/Buyahome";
// import Cities from "../components/Cities";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
// import Rentignahome from "../components/Rentingahome";
import Testimonials from "../components/Testimonials";
import Upcomingproject from "../components/Upcomingproject";
import Articals from "../components/Articals";
import SearchBox from "../components/Searchbox";
import Categories from "../components/Ctaegories";


function Home() {

  return (
    <>
      <Header />
      <SearchBox />
      <Categories />
      <Buyahome />
      <Upcomingproject />
      {/* <Rentignahome /> */}
      {/* <Cities /> */}
      <Articals />
      <Testimonials />
      <Navbar />
    </>
  );
}

export default Home;