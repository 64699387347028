
import './App.css';
import Routess from './routes/Routes';

function App() {
  return (
    <>
      <Routess />
    </>
  );
}

export default App;
