import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logincarusel from '../components/Logincarusel';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [touchedFields, setTouchedFields] = useState({
    email: false,
    password: false,
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const navigate = useNavigate(); // Initialize useNavigate

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === 'email') {
      setEmail(value);
      setIsButtonDisabled(
        !validateEmail(value) || password.length < 6
      );
    } else if (name === 'password') {
      setPassword(value);
      setIsButtonDisabled(
        !validateEmail(email) || value.length < 6
      );
    }
  };

  const handleBlur = (field) => {
    setTouchedFields({ ...touchedFields, [field]: true });
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleSendOTP = async () => {
    if (!validateEmail(email) || password.length < 6) {
      toast.error('Please fill in all fields with valid information.');
      return;
    }

    try {
      const response = await fetch('https://kamaupoot.microcominternationals.com/public/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          login: email,
          password: password,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        sessionStorage.setItem('token', data.token);
        toast.success('Login successful!');
        navigate('/');
      } else {
        toast.error(data.message || 'Login failed');
      }
    } catch (err) {
      toast.error('An error occurred');
      console.error(err);
    }
  };

  return (
    <section className="flex flex-col min-h-screen">
      {/* First div: Logo and Heading */}
      <div className="h-60 flex flex-col items-center top-0">
        <Logincarusel />
      </div>
      {/* Second div: Form */}
      <div className='mx-3 mb-3 mt-20'>
        <h1 className='text-2xl font-rubik font-bold text-center mb-3'>Log in or sign up</h1>
        {/* <p className='text-center font-sm text-gray-500 font-semibold mb-3'>Log in or sign up</p> */}
        <form className="space-y-5">
          <div>
            <div className="">
              <input
                id="email"
                name="email"
                value={email}
                onChange={handleInputChange}
                onBlur={() => handleBlur('email')}
                className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                type="email"
                placeholder="Email"
                required
              />
              {touchedFields.email && !validateEmail(email) && (
                <p className="text-red-500 text-sm">Invalid email address</p>
              )}
            </div>
          </div>

          <div>
            <div className="">
              <input
                id="password"
                name="password"
                value={password}
                onChange={handleInputChange}
                onBlur={() => handleBlur('password')}
                className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                type="password"
                placeholder="Enter your password"
                required
                maxLength={10}
              />
              {touchedFields.password && password.length < 6 && (
                <p className="text-red-500 text-sm">Password must be at least 6 characters</p>
              )}
            </div>
          </div>

          <div>
            <button
              type="button"
              onClick={handleSendOTP}
              disabled={isButtonDisabled}
              className="inline-flex w-full items-center justify-center rounded-md bg-btncolor px-3.5 py-2.5 font-semibold leading-7 text-white hover:bg-btncolor font-rubik"
            >
              Login
            </button>
          </div>
        </form>
        <p className='text-xs mt-3 text-center'>Don't have account? <Link to='/register'> <span>Register!</span></Link></p>
      </div>
      <div>
        {/* <p className='text-xs font-rubik text-gray-600 text-center mt-5'>By continuing, you agree to our</p> */}
        <div className='flex gap-3 justify-center mt-16 '>          <Link to="/t&c"><p className='font-rubik text-gray-600 text-center' style={{ fontSize: "11px" }}>Terms & conditions</p></Link>
          <Link to="/policy"><p className='font-rubik text-gray-600 text-center' style={{ fontSize: "11px" }}>Privacy Policy</p></Link>
        </div>
      </div>
      <ToastContainer autoClose={300} />
    </section>
  );
}

export default Login;
