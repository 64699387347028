import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { MdOutlineCurrencyRupee } from "react-icons/md";

const PieChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');
    const myChart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: ['Purchase Price', 'Cost of fit-outs', 'Stamp duty', 'Brokerage', 'Legal fee', 'Reserves'],
        datasets: [{
          data: [10, 12, 18, 13, 30, 17],
          backgroundColor: [
            '#ADE4DB',
            '#F6BA6F',
            '#EF5A6F',
            '#536493',
            '#AF47D2',
            '#E3A5C7',
          ],
          borderColor: [
            '#ADE4DB',
            '#F6BA6F',
            '#EF5A6F',
            '#536493',
            '#AF47D2',
            '#E3A5C7',
          ],
          borderWidth: 1
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              fontColor: 'black',
              fontSize: 14,
              padding: 20
            }
          }
        },

      }
    });




    return () => {
      myChart.destroy();
    };
  }, []);

  return (
    <>
      <p className='text-center text-gray-500 font-rubik mt-3'>All in Price</p>
      <div className="flex items-center justify-center mb-3">
        <MdOutlineCurrencyRupee />
        <p className="font-rubik"> 2,19,50,00,000</p>
      </div>
      <div className="font-sans leading-normal tracking-normal">
        <canvas ref={chartRef}></canvas>
      </div>
    </>
  );
};

export default PieChart;
