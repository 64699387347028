import { useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";


function Headerb() {
  const navigate = useNavigate();
  const handleClose = () => {
    navigate(-1);
  };
  return (
    <>
      <div className="shadow-xl bg-white w-full fixed top-0 z-10">
        <div className="flex  items-center ">
          <div className="flex items-center ">
            <div className="border border-gray-500 rounded-full flex m-2 p-2 bg-btncolor " onClick={handleClose}>
              <FaArrowLeftLong className="text-2xl text-white p-1" />
            </div>
          </div>
          <div className="flex items-center  ">

          </div>
        </div>
      </div>
    </>
  );
}

export default Headerb;