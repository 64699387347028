import { Link, useNavigate } from "react-router-dom";
import { IoEarthOutline } from "react-icons/io5";
import { PiShieldCheckeredFill } from "react-icons/pi";
import { TbBrowserPlus } from "react-icons/tb";
import { AiFillDribbbleCircle } from "react-icons/ai";
import { MdFeedback } from "react-icons/md";
import { FaUserCircle, FaHeart } from "react-icons/fa";
import { IoIosLogOut } from "react-icons/io";
import Headerb from "../components/Headerb";
import { BsKey } from "react-icons/bs";

function Profilemenu() {
  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.removeItem("token"); // Remove the token from session storage
    navigate("/login"); // Redirect to the login page
  };

  return (
    <>
      <Headerb />
      <div className="mt-16">
        <div className="fixed-top bg-white w-full p-3 z-20">
          <div className="mb-2 mt-2 flex justify-between items-center">
            <div className="flex items-center">
              <img
                src="assets/images/default.png"
                alt="Profile"
                className="w-10 bg-slate-400 rounded-full"
              />
              <h2 className="font-rubik text-black text-xl font-semibold ms-2">
                Pankaj
              </h2>
            </div>
          </div>
        </div>
        <hr />
        <div
          className="scrollable-content mt-3  rounded overflow-auto"

        >
          <div className="flex mt-2 justify-between w-full">
            <div className="flex flex-col items-center justify-center text-white px-3 py-2 bg-btncolor text-center rounded flex-1  mx-3">
              <FaHeart className="text-xl mb-3" />
              <p className="font-semibold text-sm font-rubik">Favourites</p>
            </div>
          </div>
          <div className="ms-3">
            <Link to="/profile">
              <div className="flex bg-white mt-3 p-1 rounded">
                <FaUserCircle className="mt-1" />
                <p className="ms-5 font-rubik">Profile</p>
              </div>
            </Link>
            <p className="text-sm text-gray-800 mt-3 font-bold font-rubik">
              More
            </p>

            <div className="mb-16">
              <Link to="/aboutus">
                <div className="flex bg-white mt-3 p-1 rounded-t">
                  <IoEarthOutline className="mt-1" />
                  <p className="ms-5 font-rubik">About</p>
                </div>
              </Link>
              <hr />
              <Link to="/support">
                <div className="flex bg-white mt-2 p-1 rounded-b">
                  <TbBrowserPlus className="mt-1" />
                  <p className="ms-5 font-rubik">Support</p>
                </div>
              </Link>
              <hr />
              <Link to="/t&c">
                <div className="flex bg-white mt-2  p-1 rounded-t">
                  <PiShieldCheckeredFill className="mt-1" />
                  <p className="ms-5 font-rubik">Terms & Condition</p>
                </div>
              </Link>
              <hr />
              <Link to="/policy">
                <div className="flex bg-white mt-2 mt p-1">
                  <AiFillDribbbleCircle className="mt-1" />
                  <p className="ms-5 font-rubik">Privacy Policy</p>
                </div>
              </Link>
              <hr />
              <Link to="/feedback">
                <div className="flex bg-white mt-2 mt p-1 ">
                  <MdFeedback className="mt-1" />
                  <p className="ms-5 font-rubik">Share Feedback</p>
                </div>
              </Link>
              <hr />
              <Link to="/changepassword">
                <div className="flex bg-white mt-2 mt p-1 ">
                  <BsKey className="mt-1" />
                  <p className="ms-5 font-rubik">Change Password?</p>
                </div>
              </Link>
              <hr />
              <div
                className="flex bg-white mt-2 mt p-1 rounded-b cursor-pointer"
                onClick={handleLogout}
              >
                <IoIosLogOut className="mt-1" />
                <p className="ms-5 font-rubik">Log out</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profilemenu;
