
import { TiBell } from "react-icons/ti";


function Header() {
  return (
    <div className="flex shadow-x w-full bg-white fixed top-0 z-10">
      <div className="flex mx-2 my-2 justify-between w-full ">
        <div className=" float-start ">
          <img src="https://kamaupoot.microcominternationals.com/public/kamaupoot.svg" alt="" />
        </div>
        <div className="content-center">
          <TiBell className="text-3xl me-3" />
        </div>
      </div>
    </div>
  );
}

export default Header;
